<template>
    <div  >
        <div style="display:flex; width:1230px;margin:0 auto;flex-direction:column">        
            <div class="ms-title" v-if="v_visible=='true'">
                <span style="float:left; ">
                    <span style="margin-left:4px;"> </span>
                    <el-link class="toolcss" style="margin-left:3px;" :underline="false"> {{fldObj["m_crcy"]}}</el-link>
                    <Divider type="vertical" class="divcolor" />  
                    <Dropdown >
                        <el-link   style="color:#409EFF;font-size:10px;" :underline='false'>
                            {{crcynm}}
                            <Icon type="ios-arrow-down"></Icon>
                        </el-link>
                        <DropdownMenu slot="list">
                            <div style="display:flex;justify-content:space-start;width:240px;flex-wrap:wrap;margin-left:5px;margin-right:5px;" >
                                <div style="width:240px;text-align:left;padding-left:10px;line-height:40px;border-bottom:1px solid #ccc;color:#606266">
                                    {{fldObj['m_selcrcy']}}
                                </div>
                                <DropdownItem  v-for="(item,index) in crcyArr" :key="index" >
                                    <div @click="selCrcy(item.ENAME,item.SUBNO2,item.CODE)" style="color:#6495ED;width:80px;text-align:left">{{item.CRCYNM}}</div>
                                </DropdownItem>
                            </div>
                        </DropdownMenu>
                    </Dropdown>           
                </span>
                <span style="float:right">
                    <span v-if="headFlag==='true' && v_show==='true' ">
                        <i class="iconfont icon-denglu" style="font-size:12px"></i>
                        <el-link :underline="false" @click="loginForm" class="toolcss"> <span style="margin-left:4px;">{{fldObj['m_login']}}</span> </el-link>
                        <Divider type="vertical" class="divcolor"/>  
                        <i class="iconfont icon-zhuce" style="font-size:14px;margin-right:4px"></i>
                        <el-link @click="regForm" :underline="false" class="toolcss">  {{fldObj['m_register']}} </el-link>
 
                    </span>
                    <el-tooltip  placement="bottom" effect="light" v-if="headFlag==='false'" popper-class="atooltip"> 
                        <div slot="content"  style="font-size:14px;">
                            <el-link :underline="false" @click="logout"><Icon type="md-exit" style="width:20px;"/>{{fldObj['m_exit']}}</el-link> 
                        </div>
                        <span style="margin-left:4px;">
                            <i class="iconfont icon-denglu" style="font-size:12px"></i>
                            <a href="javascript:void(0)" style="font-size:10px;">{{username}}
                                <i class="el-icon-arrow-down el-icon--right"></i></a>
                        </span>
                    </el-tooltip>

                    <Divider type="vertical" class="divcolor"/>  
                    <el-link @click="menuSelect" :underline='false' class="toolcss">
                        <i class="iconfont icon-huiyuanzhongxin1" style="font-size:12px;color:#409eff"></i> {{fldObj['m_member']}}
                    </el-link> 
                    <Divider type="vertical" class="divcolor"/>  
                    <Dropdown >
                        <el-link  class="toolcss" :underline='false'>
                            <i class="iconfont icon-shouji1" style="font-size:14px;color:#409eff"></i> 
                            <span style="margin-right:10px;">{{fldObj['m_app']}}</span>
                        </el-link>
                        <DropdownMenu slot="list">
                            <div style="display:flex; width:130px; " >
                                <div style="margin:10px ;">
                                    <qrcode :url="mpUrl" :iconurl="iconUrl"  :wid="110" :hei="110" :imgwid="50" :imghei="50"></qrcode>
                                </div>     
                            </div>
                        </DropdownMenu>
                    </Dropdown> 
                </span> 
            </div> 
            <!-- 第二行 -->
            <div  style="width:1230px;display:flex;background:#fff">
                <div style="height:40px;margin-top:3px;width:220px;  ">
                    <el-link :underline="false" @click="mainPage" style="float:left">
                        <img style="height:31px; "   src="@/assets/logo.png"/>
                        <span style="color:#2d8cf0;font-weight: 700;font-size: 18px;">{{fldObj['m_kreco']}}</span>
                    </el-link>
                </div>
                <div style="width:730px; ">                       
                    <el-input :placeholder="fldObj['m_search']" v-model="textparam" @keyup.enter.native="queryData" size="large" style="width:80%;border:2px solid #409EFF; ">
                        <el-select v-model="selparam" slot="prepend"   style="width:120px;">
                            <el-option  value="PRD" :label="fldObj['m_product']" style="width:120px;"  ></el-option>
                            <el-option  value="DATA" :label="fldObj['m_sheet']" style="width:120px; "  ></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-search" @click="queryData" style="font-size:22px;color:#409EFF"></el-button>
                    </el-input> 
                </div>
   
                <div v-if="bomFlag" style="height:35px;margin-top:15px; width:130px;border:1px solid #E4E7ED;margin-right:25px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap">
                    <el-image src="/assets/authorize/Excel.png" style="float:left;width:35px;height:35px;"></el-image>
                    <el-link @click="bomMgt" :underline="false" style="margin-top:-25px;">{{fldObj['m_bom']}}</el-link>
                </div>
                <div v-if="bomFlag" style="float:right;margin-right:0px;">
                    <el-radio-group v-model="chklang" size="medium"  @change="toggleLang">
                        <el-radio-button  label='CN'>CN </el-radio-button>
                        <el-radio-button  label= 'EN'> EN</el-radio-button>
                    </el-radio-group>  
                </div> 
           
            </div>                   
        </div>                 
        <div v-show="toolFlag">
            <el-row mode="horizontal"   class="el-menu-css"   >                      
                <el-col :span="4">
                    <div @click="allCat"   :class="catFlag===true?'allsel':'divall'">{{fldObj['m_category']}}</div>
                </el-col> 
                <el-col :span="3" v-for="(item, index) in menuList" :key="index"> 
                    <!-- 品牌 -->
                    <div :class="powerFlag===true?'power-link':'el-dropdown-link'" @click="powEvent" v-if="item.frmurl==='38646'">{{chklang=='CN'?item.name:item.ename}}</div>
                    <!-- 数据手册 -->
                    <div :class="techFlag===true?'power-link':'el-dropdown-link'" @click="techEvent" v-else-if="item.frmurl==='38624'">{{chklang=='CN'?item.name:item.ename}}</div>
                    <!-- 解决方案 -->
                    <div :class="mthFlag===true?'power-link':'el-dropdown-link'" @click="mthEvent" v-else-if="item.frmurl==='38737'">{{chklang=='CN'?item.name:item.ename}}</div>
                    <span v-else>
                        <div  class=" el-dropdown-link" >
                            <Dropdown>
                                <span style="cursor:pointer">{{chklang=='CN'?item.name:item.ename}}<i class="el-icon-arrow-down el-icon--down" style="margin-left:3px;"></i></span>
                                <DropdownMenu slot="list">
                                    <div v-if="item.frmurl=='38734'" style="display:flex; max-width:395px;flex-wrap:wrap;max-height:550px;overflow-y:auto;background:#fff " >     
                                        <div v-for="(item, ind) in supData" :key="ind" style="display:flex;flex-wrap:wrap;width:190px;overflow-y:auto;height:50px; "  >     
                                            <div style="display:flex; ">
                                                <div v-if="item.SUPICON" style="width:60px;height:35px; " >
                                                    <el-image   :src="item.SUPICON" fit="fill" style="width:50px;height:30px;padding-top:8px;padding-right:5px;" ></el-image>
                                                </div>
                                                <div v-else style="width:50px;height:35px; ">
                                                    <i class="iconfont icon-wodegongyingshang" style="font-size:24px;color:#409eff"></i>
                                                </div>
                                                <div style=" height:30px; line-height:35px; width:130px;text-align:left;padding-left:5px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;" >
                                                    <el-link :underline="false" v-bind:supcode="item.SUPNO"  @click="menuClick(item.SUPNO,item.SUPNM,'/hotBrand',item.BANDPATH)"><span >{{chklang=='CN'?item.SUPSNM:item.SUPSENM}}</span></el-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else style=" max-width:385px; " >     
                                        <div v-for="(subitem, ind) in  item.children" :key="ind" style="display:flex;flex-wrap:wrap;width:180px; height40px; "  >     
                                                <div   style="width:20px;height:35px; ">
                                                    <!-- <i class="iconfont icon-yuancailiaoxilie" style="font-size:24px;color:#409eff"></i> -->
                                                </div>
                                                <el-link :underline="false"   @click="menuClick(subitem.code,subitem.name,subitem.frmurl,'')">{{chklang=='CN'?subitem.name:subitem.ename}}</el-link>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div> 
                         
                    </span>
                </el-col>              
            </el-row>
        </div> 
    </div>
</template>
<script>
    import { paramBase,paramData ,paramDataOrder} from '../../api/Select'
    import qrcode from 'vue_qrcodes'
    export default{
        props: {
            v_show:{type:String ,default:'true'},
            v_visible:{type:String ,default:'true'},
        },
        data () {
            return {
                mpUrl:'https://dao.ipskre.com',
                iconUrl:'assets/authorize/logo.png',
                supData:[],
                username:'',
                toolFlag:true,
                headFlag:'true',
                menuList:[],
                chklang:'CN',
                selparam:'PRD',
                textparam:'',
                v_form:'',
                catFlag:false, 
                powerFlag:false, 
                techFlag:false, 
                mthFlag:false, 
                crcyArr:[],
                crcynm:'', //显示货币
                cyno:'￥',
                fldObj:{},
                bomFlag:true,
            }
        },
        components: {
            qrcode,  
        },
 
        mounted () {
 
        },
        created () {
            this.crcynm ='¥CNY'
            this.getMenuList()
            this.langSet()
            this.selparam ='PRD'
            //货币
            paramData('parentno','19665','V_sys_base').then((res)=>{
                this.crcyArr =res.data.result
            });
            paramDataOrder('imgty','SUP','V_BS_IMG',' order by sortby,lstseq').then((res)=>{
                this.supData =res.data.result
            })    
            this.pushMsg()      
        },
        methods: {
            // 接收服务器推送的信息
            pushMsg(){
                var that =this ,v_user =this.$cookies.get('v_telno' )
                if("WebSocket" in window){
                    var ws =new WebSocket(this.$store.state.wsUrl+v_user)
                    ws.onmessage =function(evt){
                        var receive_msg =evt.data
                        if (receive_msg=='Logon'){ //登录成功
                            that.headFlag='false'
                            that.username =v_user
                            that.$forceUpdate()
                            console.log('socket_value:' +receive_msg+'...'+v_user) 
                        }
                    }
                }else{
                    this.$alert('Your browser does not support websocket', 'Prompt', {
                        confirmButtonText: 'Sure',
                        showClose:false,
                        type:'warning'
                    });
                }
            }, 
            getField(){
                let that =this
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/getBaseData',
                    data: {p_key:'idseq', p_value:'55510',p_table:'V_BS_PAGEDTL'},
                }).then(res=>{ 
                    for(let k=0 ;k<res.data.result.length; k++){
                        that.fldObj[res.data.result[k]['SUBNO']] =that.chklang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                    }
                    that.$forceUpdate()
                })        
            },
            //bom管理
            bomMgt(){
                if (this.$cookies.get('v_telno')){
                    let routeUrl = this.$router.resolve({
                        path: "/member/basic_info",
                        query: {active:'/pomgt/bom_bill' },
                    });
                    window.open(routeUrl.href, '_blank'); 
                }else{
                    let routeUrl = this.$router.resolve({
                        path: "/frontLogin",
                        query: {lang:this.chklang ,form:'bom'},
                    });
                    window.open(routeUrl.href, '_self');
                }
            },
            //显示货币
            selCrcy(VAL,CNAME,CODE){
                this.crcynm =CNAME+VAL
                this.cyno=CNAME
                this.$emit('child-crcy',{crcy:CODE,sname:CNAME,cname:VAL})
            },
            //后台中心 query传参相当于是get请求，页面跳转时参数会在地址栏上显示，params传参相当于是Post请求，请求的参数不会在地址栏上显示
            // 传参的是router,接收参数的是route ,this.$route.query.id , this.$route.params.id,使用params传参时刷新页面参数会消失
            //配置路由在 path加 /:id 对应需要传的参数名
            
            //菜单选择事件 target="_blank"
            menuSelect(){           
                let v_user =this.$cookies.get('v_telno')
                if (v_user===''||typeof(v_user)==='undefined'||v_user===null){
                    let routeUrl = this.$router.resolve({
                        path: "/frontLogin",
                        query: {lang:this.chklang,form:'base' },
                    });
                    window.open(routeUrl.href, '_blank');
                }else{
                    let routeUrl = this.$router.resolve({
                        path: "/member/basic_info",
                        query: {lang:this.chklang,pid:'/member/per_info' },
                    });
                    window.open(routeUrl.href, '_blank');                    
                }
            },
            //登录
            loginForm(){
                let routeUrl = this.$router.resolve({
                    path: "/frontLogin",
                    query: {lang:this.chklang ,form:'main'},
                });
                window.open(routeUrl.href, '_self');
            },
            //注册
            regForm(){
                let routeUrl = this.$router.resolve({
                    path: "/register",
                    query: {lang:this.chklang},
                });
                window.open(routeUrl.href, '_blank');
            },
            //登出
            logout(){
                this.headFlag='true'
                this.$cookies.set('v_telno',''); //用户编码  
                this.v_show ='true'

                //this.$forceUpdate()
                // let routeUrl = this.$router.resolve({
                //     path: '/',
                // });  
                // window.open(routeUrl.href, '_self');         
            },
            mainPage(){
 
                let routeUrl = this.$router.resolve({
                    path: '/',
                    query: {lang:this.chklang }
                });  
                window.open(routeUrl.href, '_self');         
            },
            //全部分类
            allCat(){
                this.catFlag =true
                this.powerFlag=false
                this.techFlag=false
                this.mthFlag=false
                this.$emit('child-menuSelect','category',this.cyno)
            },
 
            //Logo 首页
            iconClick(){
                this.$emit('child-power')
                this.powerFlag=false 
                this.catFlag=false
                this.techFlag=false
                this.mthFlag=false
            },
            //国产电源器件
            powEvent(){
                this.catFlag=false
                this.techFlag=false
                this.mthFlag=false
                this.powerFlag=true
                this.$emit('child-menuSelect','power','')
            },
            //技术文件
            techEvent(){
                this.catFlag=false
                this.powerFlag=false
                this.mthFlag=false
                this.techFlag=true
                this.$emit('child-menuSelect','technology','')
            },
            //解决方案
            mthEvent(){
                this.catFlag=false
                this.powerFlag=false
                this.mthFlag=true
                this.techFlag=false
                this.$emit('child-menuSelect','solution','')
            },
            menuClick(code,name,val,path){
                if (val==='/docmgt/doc_updown'){ //文档
                    this.v_form ='doc'
                    let routeUrl = this.$router.resolve({
                        path: val,
                        query: {form: this.v_form},
                    });
                    window.open(routeUrl.href, '_blank');
                }else if (val==='/prdmgt/prd_datalib'){ //产品库
                    this.v_form ='prd'
                    val ='/prd_upload'
                    let routeUrl = this.$router.resolve({
                        path: val,
                        query: {form: this.v_form},
                    });
                    window.open(routeUrl.href, '_blank');
                }else{  //制造商
                    let routeUrl = this.$router.resolve({
                        path: val,
                        query: {p_code: code,p_name:name,p_path:path,lang:this.chklang},
                    });
                    window.open(routeUrl.href, '_blank');
                }
                
                //this.$emit('child-menuSelect',val)
            },
            toggleLang(){
                this.getField()
                this.$emit('child-menuSelect','lang',this.chklang)
            },
            //查询产品 或 数据手册
            queryData(){
                let param =this.textparam.split(' ')
                let param1 ='', param2 ='',param3=''
                if (param.length==2){
                    param1 =param[0]
                    param2 =param[1]
                    param3='^#'
                }else if (param.length>=3){
                    param1 =param[0]
                    param2 =param[1]
                    param3 =param[2]
                }else{
                    param1=this.textparam
                    param2='^#'
                    param3='^#'
                }
                //matnm ,modelnm ,facidno, supnm,matdesc  制造商货号：facidno ，产品序号：IDNO ,KRECOPN :景荣PN
                var cond=' and (lower(matnm) like lower(\'%'+param1+'%\') or lower(modelnm) like lower(\'%'+param1+'%\')'+
                          ' or lower(facidno) like lower(\'%'+param1+'%\') or lower(supnm) like lower(\'%'+param1+'%\')'+
                          ' or (lower(matdesc) like lower(\'%'+param1+'%\')' +' or lower(krecopn) like lower(\'%'+param1+'%\')'+' or lower(idno) like lower(\'%'+param1+'%\')'+
                          ' or lower(supnm) like lower(\'%'+param2+'%\')'+' or lower(krecopn) like lower(\'%'+param2+'%\')'+' or lower(idno) like lower(\'%'+param2+'%\')'+
                          ' or lower(matnm) like lower(\'%'+param2+'%\')  or lower(modelnm) like lower(\'%'+ param2+'%\')'+
                          ' or lower(matdesc) like lower(\'%'+param2+'%\')) or lower(facidno) like lower(\'%'+param2+'%\') '+
                          ' or lower(supnm) like lower(\'%'+param3+'%\')'+' or lower(krecopn) like lower(\'%'+param3+'%\')'+' or lower(idno) like lower(\'%'+param3+'%\')'+
                          ' or lower(matnm) like lower(\'%'+param3+'%\')  or lower(modelnm) like lower(\'%'+ param3+'%\')'+
                          ' or lower(matdesc) like lower(\'%'+param3+'%\')  or lower(facidno) like lower(\'%'+param3+'%\') '+
                          ')'
                if (this.selparam==='PRD'){ //产品搜索KRECOPN ,--景荣PN FACIDNO, --供应商型号MODELNM , --类别 SUPNM, --厂牌MATDESC, --产品描述
                    this.catFlag =true
                    this.powerFlag=false
                    this.techFlag=false
                    this.mthFlag=false  
                    this.$emit('child-menuSelect','prdsrch',cond)
                }else{  //技术文件
                    this.catFlag=false
                    this.powerFlag=false
                    this.mthFlag=false
                    this.techFlag=true
                    this.$emit('child-menuSelect','techsrch',cond)
                }
            },
            //语言设置
            langSet(){
                if ((navigator.language || navigator.browserLanguage).toLowerCase().includes('zh-')>-1){
                    this.chklang='CN'
                    this.$store.state.lang=this.chklang
                    this.$store.commit('setLocal',this.chklang)
                }else{
                    this.chklang='EN'
                    this.$store.state.lang=this.chklang
                    this.$store.commit('setLocal',this.chklang)
                }                
            },           
            //水平导航菜单
            getMenuList(){
                this.$axios({
                    method:'post',
                    url: this.$store.state.baseUrl+'auth/login/getUserMenu' ,
                    data:{p_key:'isfront',p_value:'Y'},
                }).then(res =>{
                    this.menuList =res.data  
                })
            },
        }
    }

</script>
<style scoped lang="less">
  
  .divall{
    font-size:15px;
    color:white;
    padding-left:20px;
    line-height:50px;
    width:205px;
    text-align:left;
    cursor: pointer;
  }
  .allsel{
    font-size:15px;
    color:white;
    background-color: #ed4014;
    line-height:50px;
    width:205px;
    text-align:left;
    padding-left:20px;
    cursor: pointer;
  }   
  .el-menu-css{  
      height:50px;
      line-height:50px;
      display :flex ;
      width:1230px; 
      margin:0 auto;
      background-color: #5cadff ; 
  }
.el-dropdown-link {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    line-height:50px;
    text-align:center;
  } 
  .power-link {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    background-color: #ed4014;
    line-height:50px;
    width:155px;
    text-align:center;
  }
  .el-listitem {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    background: #5cadff;
    height:50px;
    line-height:50px;
    text-align:center;
  } 
  .ms-title {
    width: 1230px; 
    margin:0px auto;  
    background-color:#d7d7da;
    height:35px;
    line-height: 30px;
    margin-top:0px;
    font-size: 10px;
    color: #2b85e4;
  }
  .toolcss{
      font-size:10px;
      color:#2b85e4
  } 
</style>
<style lang="less">
    .dropdown-css{
        width:433px !important;
        padding-left:0px !important; 
        margin-right:-48px !important; 
        margin-top:50px !important;   
    }
</style>