<template>
    <div>
        <!-- 说明 -->
        <!-- <div style="margin-top:20px;display:flex;justify-content:space-around;width:1230px;margin:0 auto; ">
            <div style="display:flex;">
                <div class="circle"><span>多</span></div>
                <span style="margin-left:10px;font-size:18px;color:black;margin-top:5px;">品类齐全，无忧畅选</span>
            </div>
            <div style="display:flex; ">
                <div class="circle"><span>快</span></div>
                <span style="margin-left:10px;font-size:18px;color:black;margin-top:5px;">有库存，急速直发</span>
            </div>
            <div style="display:flex; ">
                <div class="circle"><span>真</span></div>
                <span style="margin-left:10px;font-size:18px;color:black;margin-top:5px;">真正行货，品质保证</span>
            </div>
            <div style="display:flex; ">
                <div class="circle"><span>好</span></div>
                <span style="margin-left:10px;font-size:18px;color:black;margin-top:5px;">体验极好，轻松购物</span>
            </div>
        </div> -->
        <div class="footrow">
            
            <template v-for="(item, index) in footArr" > 
                <div  :key="index">
                    <div v-if="index==0" class="top-menu" >
                            {{LANG=='CN'?item.CNAME:item.ENAME}} 
                    </div>
                    <div v-else-if="index==1 ||index==2" :class="LANG=='CN'?'top-menu':'top-menu'" >
                            {{LANG=='CN'?item.CNAME:item.ENAME}} 
                    </div>
                    <div v-else class=" top-menu" >
                            {{LANG=='CN'?item.CNAME:item.ENAME}} 
                    </div>
                    <template v-for="(subitem,ind ) in item.children">
                        <div :key="ind" class="home-left-sub">
                            <el-link v-if="index==0" :underline="false" @click="menuClick(subitem.URL,subitem.TAG )">
                               <div class="rowdiv" :title="LANG=='CN'?subitem.CNAME:subitem.ENAME"> {{LANG=='CN'?subitem.CNAME:subitem.ENAME}} </div>
                            </el-link>
                            <el-link v-else-if="index==1||index==2" :underline="false" @click="menuClick(subitem.URL,subitem.TAG)">
                               <div class="rowdiv"  :title="LANG=='CN'?subitem.CNAME:subitem.ENAME"> {{LANG=='CN'?subitem.CNAME:subitem.ENAME}} </div>
                            </el-link>
                            <div v-else class="rowdiv2" :title="LANG=='CN'?subitem.CNAME:subitem.ENAME" style="font-weight:400;font-size:14px;color:#606266"> {{LANG=='CN'?subitem.CNAME:subitem.ENAME}}</div>
                        </div>
                    </template>
                </div>
            </template>
        </div> 
        <div class="singlerow">
 
            {{fldObj.f_right}}
            <Divider type="vertical" class="divcolor" />  
            {{fldObj.f_advice}}
            <Divider type="vertical" class="divcolor"/>  
            <span style=" display:inline-block; " >
                <el-link @click="backPlat" :underline="false"  >
                    <span style="font-weight:600;font-size:10px;color:#409eff">{{fldObj.f_back}}</span>
                </el-link>
            </span>
            <Divider type="vertical" class="divcolor"/> 
            <span style=" display:inline-block;vertical-align:middle;line-height:30px;padding-top:6px;">
                <a target="_blank" title="51la网站统计" href="https://v6.51.la/land/JhjPA9l7Bb5zzTxj" style="padding-top:5px;"><img src="https://sdk.51.la/icon/3-3.png" ></a>
            </span>
       
        </div>
        <div class="singlerow">            
            <el-image src="/assets/authorize/wx.png"  style="height:35px;margin-right:10px;"></el-image>            
            <el-image src="/assets/authorize/alipay.png" style="height:35px;margin-right:20px;"></el-image>
            <el-image src="/assets/authorize/swift.png" style="height:35px;"></el-image>
        </div>
    </div>
</template>
<script>
export default {
 
    data () {
        return {
            footArr:[], //foot类别
            fldObj:{},
            LANG:this.$store.state.lang,
        }
    },
    created () {
        this.getFootList() //foot 菜单数据
    },
    methods: {
        getField(v_lang){
            let that =this
            this.LANG =v_lang
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55718',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] = v_lang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        backPlat(){
            let routeUrl = this.$router.resolve({
                path: "/backLogin",
                query: {lang:this.$store.state.lang },
            });
            window.open(routeUrl.href, '_blank');
        },
        //foot item click event
        menuClick(url,tag){
            if ( url.indexOf('/menu')>-1){
                let routeUrl = this.$router.resolve({
                    path: url,
                    query: {lang:this.LANG, name:tag,url:url },
                });
                window.open(routeUrl.href, '_blank');
            }else{
                let routeUrl = this.$router.resolve({
                    path: '/helper',
                    query: {lang:this.LANG,fileUrl:url },
                });
                window.open(routeUrl.href, '_blank');
            }
        },
        // foot menu 
        getFootList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getMainSub' ,
                data:{p_key:'ISFRONT',p_value:'Y',maintab:'V_BS_FRONT',subtab:'V_BS_FRONTitm',p_cond:' order by sortby,idseq'},
            }).then(res =>{
                this.footArr =res.data.result  
            })
        },
    }
}
</script>
<style scoped>
    .footrow{
      display:flex;
      justify-content:space-start;
      flex-wrap:wrap;
      width:1250px;
      margin:0 auto;
  }
   .top-menu{
        width:310px; 
        font-size:18px;
        font-weight:600;
        margin-top:15px;
        text-align: left; 
   }
 
    .home-left-sub{
      margin-top:10px;
      height:25px;
      line-height:25px;
      text-align: left; 
    } 
    .divcolor{
        background-color: #5cadff;
    }
    .singlerow{
        margin-top: 17px;
        text-align: center;
        font-size:10px;
        color:#808695;
        border-radius: 2px;
        line-height:30px; 
        
    } 
    /* .circle{
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      overflow:hidden;
      width:40px;
      height:40px;
      border:2px solid red;
      z-index: 12;
      background-color: #fff;
  }
  .circle span{
      height:100%;
      display:block;
      font-size:22px;
      font-weight:600;
      color:red;
      vertical-align:center;
      text-align:center;
  } */
  .rowdiv{
      white-space:nowrap;
      width:210px;
      overflow:hidden;
      text-overflow:ellipsis;
      
  }
  .rowdiv2{
      white-space:nowrap;
      width:300px;
      overflow:hidden;
      text-overflow:ellipsis;
  }
</style>