<template>
    <div>
        <el-tooltip  placement="left" effect="light"  > 
            <div slot="content"  style="font-size:14px;">
                <span >{{fldObj['f_hot']}} <br><div style="margin-top:5px;">0760-22582166</div></span>
            </div>
            <div class="rightfloat" style="bottom:600px;"><i style="font-size:24px;" class="iconfont icon-zixundianhua"/>{{fldObj['f_telno']}} </div>   
        </el-tooltip>
        <el-tooltip  placement="left" effect="light"  > 
            <div slot="content"  style="font-size:14px;">
                <span >{{fldObj['f_time']}} <br><div style="margin-top:10px;">{{fldObj['f_day']}}：8:30-17:30</div></span>
                <div style="margin-top:8px;">{{fldObj['f_pub']}}QQ：258212403</div> 
            </div>
            <div class="rightfloat" style="bottom:535px;" ><i style="font-size:24px;"  class="iconfont icon-zixunkefu"/> {{fldObj['f_adv']}}</div>
        </el-tooltip> 
        <div class="rightfloat" @click="menuSelect" style="bottom:470px;" ><i style="font-size:24px;" class="iconfont icon-huiyuanzhongxin"/> {{fldObj['f_mem']}}</div>      
 
            <div class="rightfloat" style="bottom:405px;" @click="carCheck">
                <el-badge v-if="carQty>0" :value="carQty" class="bgitem"  />
                <i style="font-size:24px;" class="iconfont icon-gouwuche"/> 
                {{fldObj['f_shop']}}
            </div>
 
        <div class="rightfloat" style="bottom:340px;" ><i style="font-size:24px;" class="iconfont icon-lishijilu"/> {{fldObj['f_history']}}</div> 
    </div>
</template>
<script>
export default {
    data () {
        return {
           carQty:'0', 
           LANG:'',
           fldObj:{},
        }
    },
    created () {
        let v_userno =this.$cookies.get('v_telno')
        let v_uuid =this.$cookies.get('v_uuid')
        //传入 p_frmid 参数是为了生成 表单 的编码
        this.$axios({  
            method: 'post',
            url:this.$store.state.baseUrl+'auth/login/getRecordCnt',
            data: { p_table:'V_CAR_LIST',p_cond:' QTY>0 and (idseq=\''+v_userno+'\' or idseq=\''+v_uuid+'\')'},
        }).then(res=>{ 
            this.carQty =res.data.result
        })
    },
    methods: {
        getField(v_lang){
            let that =this
            this.LANG =v_lang
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55718',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] = v_lang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        //购物车 标记
        setCar(val,facidno){
            this.carQty =val
            //然后调用focus方法
            // this.$nextTick(()=>{
            //     this.$refs.popover.focus()
            // })
        },
        //会员中心
        menuSelect(){           
            let v_user =this.$cookies.get('v_telno')
            if (v_user===''||typeof(v_user)==='undefined'||v_user===null){
                let routeUrl = this.$router.resolve({
                    path: "/frontLogin",
                    query: {lang:this.LANG ,form:'base'},
                });
                window.open(routeUrl.href, '_blank');
            }else{
                let routeUrl = this.$router.resolve({
                    path: "/member/basic_info",
                    query: {lang:this.LANG },
                });
                window.open(routeUrl.href, '_blank');                    
            }
        },
        //购物车
        carCheck(){
            let routeUrl = this.$router.resolve({
                path: "/ordmgt/carlist",
                query: {lang:this.LANG},
            });
            window.open(routeUrl.href, '_blank');   
        }
    }
}
</script>
<style scoped >
    .rightfloat{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        background-color: rgb(255,255,253);
        padding:3px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;  
        cursor:pointer;
        z-index:999999;
    }
    .rightfloat:hover{
        background-color:#2b85e4;
        color:white;
    }
    .rightfloat .iconfix:hover{
        color:white;
    }
    .iconfix{
        color:#409EFF;
        font-size:24px;
        font-weight:600;
        margin-bottom:5px;
    }
    .bgitem{
        margin-bottom: -15px;
        margin-left: 30px;
    }
</style>